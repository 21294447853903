// extracted by mini-css-extract-plugin
export var header = "V_v";
export var item = "V_bK";
export var itemImage = "V_cJ";
export var itemIsExternal = "V_cS";
export var itemIsInView = "V_cQ";
export var itemIsLinked = "V_cR";
export var itemText = "V_cK";
export var itemTime = "V_cT";
export var itemTimeIsBig = "V_cV";
export var logo = "V_bS";
export var root = "V_s";